import { getList, remove, submit, getDetail, exportExcel } from '@/api/common'
import { mapGetters } from 'vuex'
import FileSaver from 'file-saver'
import { formatDate } from '@/util/date'
import { getToken } from '@/util/auth'
import { baseUrl } from '@/config'

export default {
  data () {
    return {
      form: {},
      search: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      params: {},
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    searchReset () {
      this.search = {}
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.search = params
      this.onLoad(this.page)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange () {
      this.onLoad(this.page)
    },
    currentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    onLoad (page) {
      getList(this.module, page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    rowSave (row, done, loading) {
      submit(this.module, row).then((res) => {
        done()
        if (res.success) {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(this.module, row).then((res) => {
        done()
        if (res.success) {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.module, row.id)
        })
        .then((r) => {
          if (r.success) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          } else {
            this.$message({
              type: 'error',
              message: r.msg
            })
          }
          this.onLoad(this.page, this.search)
        })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.module, this.ids)
        })
        .then(() => {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.module, this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    // cell方式增加数据
    addRow () {
      this.data.splice(0, 0, {
        $cellEdit: true
      })
    },

    handleExport () {
      exportExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '数据导出' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    },
    handleImport () {
      return baseUrl + '/' + this.module + '/import'
    },
    uploadHeaders () {
      return { token: getToken() }
    },
    uploadSuc (res) {
      if (res.success) {
        this.$message({ message: '操作成功', type: 'success' })
        this.onLoad(this.page)
      } else {
        this.$message({ message: '操作失败', type: 'error' })
      }
    }
  }
}
