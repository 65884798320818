<template>
  <div>
    <div class="catalogue-box" v-if="getListType == null">
      <div class="bg"></div>
      <div class="chouse-box">
        <div class="title">
          选择实验类型
        </div>
        <div class="chouse-list">
          <div :class="type === item.type ?'item-box active':'item-box'" @click="chouseItem(item.type)" :key="index" v-for="(item,index) in radioList">
            <div class="item-icon">
              <img :src="item.img"/>
            </div>
            <div class="item-name">{{item.name}}</div>
            <div class="current">
              <i class="el-icon-check"></i>
            </div>
          </div>
        </div>
        <div class="start" @click="startExp">
          <div class="icon">
            <img src="~@/assets/terminal/b8.png"/>
          </div>
          点击进入实验
        </div>
      </div>
    </div>
    <primar v-if="getListType == 0"></primar>
    <subculture v-if="getListType == 1"></subculture>
    <druggest v-if="getListType == 4"></druggest>
  </div>
</template>
<style lang="scss">
.catalogue-box{
  width: 100vw;
  height: 100vh;
  min-height: 600px;
  position: relative;
  .bg{
    height: 100vh;
    width: 100vw;
    background: url('~@/assets/terminal/b6.png');
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
  .chouse-box{
    width: 50vw;
    height: 100vh;
    min-height: 600px;
    min-width: 600px;
    position: absolute;
    background: rgba(255,255,255,0.6);
    right: 0;
    top: 0;
    box-shadow: 0px 2px 48px 0px rgba(79,58,30,0.13);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;

    .title{
      text-align: center;
      line-height: 50px;
      padding-bottom: 10px;
      color: #4085BA ;
      font-size: 24px;
      border-bottom: 1px solid #4085BA;
    }
    .start{
      height: 80px;
      background: #4085BA;
      color: #fff;
      font-weight: bold;
      width: 300px;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 100px;
      text-align: center;
      font-size: 22px;
      display: flex;
      .icon{
        background: #fff;
        border-radius: 100px;
        width: 70px;
        height: 70px;
        margin-left: 5px;
        margin-right: 20px;
        img{
          width: auto;
          height: 70px;
        }
      }
    }
    .chouse-list{
      display: flex;
      flex-wrap: wrap;
      min-width: 200px;
      padding: 30px;
      .item-box{
        display: flex;
        height: 82px;
        padding: 5px;
        background: #DBE7F9;
        border-radius: 10px;
        width: 45%;
        border: 1px solid #DBE7F9;
        box-sizing: border-box;
        margin-bottom: 15px;
        margin-right: 15px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover{
          border: 1px solid #4085BA;
        }

        .current{
          color: #fff;
          position: absolute;
          right: -30px;
          top: -30px;
          width: 60px;
          height: 60px;
          background: #4085BA;
          transform: rotate(45deg);
          text-align: center;
          display: none;
          i{
            margin-top: 40px;
            transform: rotate(-45deg);
          }
        }
        &.active{
          border: 1px solid #4085BA;
          .current{
            display: block;
          }
        }
        .item-icon{
          background: #fff;
          border-radius: 50%;
          width: 70px;
          height: 70px;
          padding: 15px;
          img{
            width: auto;
            height: 40px;
            display: block;
            margin: 0 auto;
          }
        }
        .item-name{
          color: #2A448F;
          margin-left: 20px;
          line-height: 70px;
        }
      }
    }
  }
}

</style>
<script>
import primar from '@/views/terminal/experiment/primary'
import subculture from '@/views/terminal/experiment/subcultrue'
import druggest from '@/views/terminal/experiment/druggest'
export default {
  components: {
    primar,
    subculture,
    druggest
  },
  data () {
    return {
      type: null,
      getListType: null,
      radioList: [
        {
          img: require('@/assets/terminal/b1.png'),
          name: '原代培养',
          type: 0
        },
        {
          img: require('@/assets/terminal/b2.png'),
          name: '传代培养',
          type: 1
        },
        // {
        //   img: require('@/assets/terminal/b3.png'),
        //   name: '冻存'
        // },
        // {
        //   img: require('@/assets/terminal/b5.png'),
        //   name: '类器官鉴定'
        // },
        {
          img: require('@/assets/terminal/b4.png'),
          name: '药敏试验',
          type: 4
        }
      ]
    }
  },
  computed: {

  },
  methods: {
    startExp () {
      this.getListType = this.type
    },
    chouseItem (index) {
      console.log(index)
      this.type = index
    }
  }
}
</script>
