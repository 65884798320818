<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item label="药敏编号">
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="药敏编号"
              v-model="page.drugCode"></el-input>
        </el-form-item>
        <el-form-item label="样本编号">
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="样本编号"
              v-model="page.sampleCode"></el-input>
        </el-form-item>
        <el-form-item label="药敏对象">
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="药敏对象"
              v-model="page.drugObjects"></el-input>
        </el-form-item>
        <el-form-item label="冻存类型">
          <el-select placeholder="请选择" v-model="page.freezeType">
            <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in freezeTypeOptions">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="药敏人员">
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="药敏人员"
              v-model="page.operatorName"></el-input>
        </el-form-item>
        <el-form-item label="药敏时间">
          <el-date-picker
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="datetimerange"
              v-model="page.queryOperateTime"
              value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="反馈时间">
          <el-date-picker
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="datetimerange"
              v-model="page.queryFeedBackTime"
              value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="药敏是否成功">
          <el-select placeholder="请选择" v-model="page.isFit">
            <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in isFitOptions">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleFilter" class="filter-item search" icon="el-icon-search" type="primary">搜索</el-button>
          <el-button @click="reset" class="filter-item search" icon="el-icon-search" type="primary">重置</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button @click="handleCreate" icon="el-icon-plus" type="primary">新增</el-button>
      <el-button
          :disabled="this.ids.length === 0"
          @click="batchDelete()"
          icon="el-icon-delete"
          type="danger"
      >
        删除
      </el-button>
    </el-row>
    <el-table
        :data="list"
        border
        class="good-table"
        :row-key="getRowKeys"
        @selection-change="handleSelectionChange"
        element-loading-text="拼命加载中"
        fit
        highlight-current-row
        :cell-style="{'text-align':'center'}"
        :header-cell-style="{'text-align':'center'}"
        v-loading.body="listLoading">
      <el-table-column
          :reserve-selection="true"
          label="全选"
          type = 'selection'
          width="55">
      </el-table-column>
      <el-table-column label="药敏编号" prop="drugCode" width="130px">
        <template slot-scope="scope">
          <span>{{scope.row.drugCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="样本编号" prop="sampleCode" width="130px">
        <template slot-scope="scope">
          <span>{{scope.row.sampleCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="代次" prop="generation" width="60">
        <template slot-scope="scope">
          <span>{{scope.row.generation}}</span>
        </template>
      </el-table-column>
      <el-table-column label="药敏对象" prop="drugObjects" width="90">
        <template slot-scope="scope">
          <span>{{scope.row.drugObjects}}</span>
        </template>
      </el-table-column>
      <el-table-column label="冻存类型" prop="freezeType" width="90">
        <template slot-scope="scope">
          <span>{{ typeFormatter(scope.row.freezeType) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复苏日期" prop="recoveryTime" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.recoveryTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="复了几管" prop="recoveryNum" width="80">
        <template slot-scope="scope">
          <span>{{scope.row.recoveryNum}}</span>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="药敏铺板孔位编号" prop="drugTubeCode" width="140">
        <template slot-scope="scope">
          <span>{{scope.row.drugTubeConvert}}</span>
        </template>
      </el-table-column>
      <!--      <el-table-column label="铺板后4X镜照片" prop="pic">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span><img :src="imgBaseUrl + scope.row.pic" alt="" style="width: 50px;height: 50px" v-if="scope.row.pic"></span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column label="Day0照片" prop="picDay0">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span><img :src="imgBaseUrl + scope.row.picDay0" alt="" style="width: 50px;height: 50px" v-if="scope.row.picDay0"></span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column label="Day1照片" prop="picDay1">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span><img :src="imgBaseUrl + scope.row.picDay1" alt="" style="width: 50px;height: 50px" v-if="scope.row.picDay1"></span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column label="Day3照片" prop="picDay3">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span><img :src="imgBaseUrl + scope.row.picDay3" alt="" style="width: 50px;height: 50px" v-if="scope.row.picDay3"></span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column label="Day5照片" prop="picDay5">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span><img :src="imgBaseUrl + scope.row.picDay5" alt="" style="width: 50px;height: 50px" v-if="scope.row.picDay5"></span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column label="Day7照片" prop="picDay7">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span><img :src="imgBaseUrl + scope.row.picDay7" alt="" style="width: 50px;height: 50px" v-if="scope.row.picDay7"></span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column :show-overflow-tooltip="true" label="药敏报告" prop="reports" width="180px">-->
      <!--        <template slot-scope="scope">-->
      <!--          <div :key="index" v-for="(item, index) in scope.row.docVoList">-->
      <!--            <el-link :href=" imgBaseUrl + item" type="primary" v-if="item" target="_blank">-->
      <!--              {{item.split('/')[1]}}-->
      <!--            </el-link>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="药敏孔数" prop="holes" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.holes}}</span>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="药敏人员" prop="operator" width="80px">
        <template slot-scope="scope">
          <span>{{scope.row.operatorName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="药敏时间" prop="operateTime" width="150px">
        <template slot-scope="scope">
          <span>{{scope.row.operatorTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈时间" prop="feedBackTime" width="150px">
        <template slot-scope="scope">
          <span>{{scope.row.feedBackTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="药敏是否成功" prop="isFit" width="140px">
        <template slot-scope="scope">
          <span>{{ typeFormatter1(scope.row.isFit) }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="handleUpdate(scope.row)" size="small" type="text" icon="el-icon-edit">开始实验</el-button>
          <el-button @click="handleReport(scope.row.id)" size="small" type="text" icon="el-icon-document">生成报告</el-button>
          <el-button
              @click="handleDelete(scope.row)"
              size="small"
              type="text"
              icon="el-icon-delete"
              v-if="scope.row.status!='deleted'">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="end pagination-container" v-show="!listLoading">
      <el-pagination
          :current-page.sync="page.currentPage"
          :page-size="page.pageSize"
          :page-sizes="[10,20,30, 50]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </basic-container>
</template>

<script>
import { getList, deleteDrugTest, remove } from '@/api/exp/drugtest'
import { fileSaveUrl } from '@/config/index'

export default {
  data () {
    return {
      total: null,
      list: null,
      ids: [],
      listLoading: true,
      imgBaseUrl: fileSaveUrl,
      freezeTypeOptions: [{
        label: '冻存前',
        value: '0'
      }, {
        label: '冻存后',
        value: '1'
      }],
      isFitOptions: [{
        label: '否',
        value: '0'
      }, {
        label: '是',
        value: '1'
      }],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
        drugCode: '',
        sampleCode: '',
        drugObjects: '',
        freezeType: '',
        operatorName: '',
        queryOperateTime: '',
        queryFeedBackTime: '',
        isFit: ''
      }
    }
  },
  created () {
    // this.onLoad(this.page)
    this.onLoad()
  },
  methods: {
    handleReport (id) {
      window.open('/report/drugest?experimentId=' + id)
    },
    getRowKeys (row) {
      return row.id
    },
    handleSelectionChange (val) {
      const checked = val.map(item => {
        return item.id
      })
      this.ids = checked
    },
    batchDelete () {
      remove(this.ids.join(',')).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.ids = []
          this.handleFilter()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    onLoad () {
      getList(this.page.currentPage, this.page.pageSize, this.page).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleChangePolicyType (e) {
    },
    handleChangeArea (e) {
      let selectedAreaName = {}
      selectedAreaName = this.areaOptions.find((item) => { // 这里的chargingWorkNameList就是上面遍历的数据源
        return item.dwdmValue === e
        // 筛选出匹配数据，是对应数据的整个对象
      })
      this.form.areaName = selectedAreaName.label
    },
    // 上传成功
    uploadFileSuccess (response, file, fileList) {
      console.log(response)
      if (response.success) {
        this.fileList.push(file)
        this.form.policyUrl = response.data
      } else {
        this.$message.error(response.msg)// 文件上传错误提示
        this.fileList = []
      }
    },
    beforeUpload (file) {
      console.log(file.type, '222')
      if (file.type !== 'doc' && file.type !== 'docx' && file.type === 'pdf') {
        this.fileList = []
        this.$notify({
          title: '上传失败',
          message: '文件格式必须是pdf/word',
          type: 'error',
          duration: 2000
        })
        return false
      }
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleFile (e) {
    },
    getArea () {
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    reset () {
      this.page.pageSize = 10
      this.page.currentPage = 1
      this.page.total = 0
      this.page.drugCode = ''
      this.page.sampleCode = ''
      this.page.operator = ''
      this.page.queryOperateTime = ''
      this.page.queryFeedBackTime = ''
      this.page.isFit = ''
      this.page.freezeType = ''
      this.page.freezeCode = ''
      this.page.drugObjects = ''
      this.handleFilter()
    },
    handleCreate () {
      this.$router.push({
        path: '/terminal/cleanBench',
        query: { templateType: 5 }
      })
    },
    handleUpdate (row) {
      this.$router.push({
        path: '/terminal/cleanBench',
        query: { experimentId: row.id, templateType: 5 }
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('是否删除该实验?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDrugTest(row.id).then(res => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.handleFilter()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保
    },
    typeFormatter: function (freezeType) {
      switch (freezeType) {
        case '0':
          return '冷冻前'
        case '1':
          return '冷冻后'
      }
    },
    typeFormatter1: function (status) {
      switch (status) {
        case '0':
          return '否'
        case '1':
          return '是'
      }
    }
  }

}
</script>

<style>
.padding-row {
  margin-bottom: 15px;
}
</style>
